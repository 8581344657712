<template>
  <el-dialog
    title="修改密码"
    :visible.sync="show"
    width="500px"
    :before-close="onClose"
    append-to-body
  >
    <el-form
      label-width="80px"
      :model="passwordForm"
      status-icon
      :rules="passwordRules"
      ref="passwordForm"
    >
      <el-form-item label="新密码" prop="newPassword">
        <el-input
          type="password"
          v-model="passwordForm.newPassword"
          placeholder="请输入新密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="againPassword">
        <el-input
          type="password"
          v-model="passwordForm.againPassword"
          placeholder="请再次输入新密码"
        ></el-input>
      </el-form-item>
    </el-form>
    <div style="text-align:right">
      <el-button :loading="loading" type="primary" @click="onSubmit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  Button,
  Dialog,
  Form,
  FormItem,
  Input,
  Message
} from "element-ui";
import { editUser } from "@/api/user";
import { getUserInfo } from "@/utils/auth";
export default {
  name: "ChangePwdDialog",
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input
  },
  data() {
    let validateNewPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        callback();
      }
    };
    let validateAgainPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.passwordForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      show: false,
      passwordForm: {
        newPassword: "",
        againPassword: "",
      },
      passwordRules: {
        newPassword: [{ validator: validateNewPass, trigger: "blur" }],
        againPassword: [{ validator: validateAgainPass, trigger: "blur" }],
      },
      loading: false
    };
  },
  computed: {
    userInfo() {
      return getUserInfo()
    }
  },
  methods: {
    onClose() {
      this.$refs["passwordForm"].resetFields();
      this.show = false;
      this.passwordForm = {
        newPassword: "",
        againPassword: "",
      };
    },
    onSubmit() {
      this.$refs["passwordForm"]['validate'](valid => {
        if (valid) {
          this.loading = true;
          editUser(this.userInfo['id'], {
            password: this.passwordForm.newPassword
          }).then(() => {
            Message({
              type: "success",
              message: '用户密码修改成功',
            });
            this.$refs["passwordForm"].clearValidate();
            this.show = false;
          })
          .finally(() => {
            this.loading = false;
          });

        } else {
          return false;
        }
      })
    }
  }
}
</script>