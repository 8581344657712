<template>
  <el-dropdown trigger="click" @command="onCommand">
    <span class="vv-dropdown-link">
      {{ userInfo['username'] }}
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        command="changePwd"
        >修改密码</el-dropdown-item
      >
    </el-dropdown-menu>
    <change-pwd-dialog ref="changePwd" />
  </el-dropdown>
</template>

<script>
import {
  Dropdown,
  DropdownMenu,
  DropdownItem
} from "element-ui"
import { getUserInfo } from "@/utils/auth"
import ChangePwdDialog from "./changePwdDialog"
export default {
  name: "UserButton",
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    ChangePwdDialog
  },
  computed: {
    userInfo() {
      return getUserInfo()
    }
  },
  methods: {
    onCommand(item) {
      this.$refs[item]['show'] = true;
    }
  }
}
</script>