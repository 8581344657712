import axios from "@/utils/request";

export function getUser(data) {
  return axios({
    url: "/adminusers",
    method: "get",
    params: data,
  });
}

export function editUser(id, data) {
  return axios({
    url: `/adminusers/${id}/passwords`,
    method: "put",
    data,
  });
}

export function readUser(id) {
  return axios({
    url: `/adminusers/${id}`,
    method: "get",
  });
}

export function addUser(data) {
  return axios({
    url: "/adminusers",
    method: "post",
    data,
  });
}

export function clearAllData(data) {
  return axios({
    url: "/clearalldata",
    method: "post",
    data,
  });
}

export function getScanLimit() {
  return axios({
    url: `/scanlimit`,
    method: "get",
  });
}

export function setScanLimit(data) {
  return axios({
    url: "/scanlimit",
    method: "post",
    data,
  });
}
