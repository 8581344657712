<template>
  <div class="vv-wrapper">
    <el-container>
      <navbar />

      <el-container direction="vertical">
        <appbar />

        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { Container, Main } from "element-ui";
import { Navbar, Appbar } from "@/layout";
export default {
  name: "SBCS",
  components: {
    [Container.name]: Container,
    [Main.name]: Main,
    Navbar,
    Appbar,
  },
};
</script>
