<template>
  <el-header class="vv-app-bar">
    <el-row>
      <el-col :span="12">
        <el-button
          type="text"
          class="nav-bar-button"
          @click="onShow"
          :icon="status ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
        ></el-button>
      </el-col>
      <el-col class="vv-app-bar_r" :span="12">
        <user-button></user-button>
        <logout-button></logout-button>
      </el-col>
    </el-row>
  </el-header>
</template>

<script>
import { Header, Row, Col, Button } from "element-ui";
import { UserButton, LogoutButton } from "@/components";
export default {
  name: "Appbar",
  components: {
    [Header.name]: Header,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    UserButton,
    LogoutButton,
  },
  data() {
    return {
      status: false,
    };
  },
  methods: {
    onShow() {
      this.status = !this.status;
      this.$store.commit("isCollapse", this.status);
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
